import { boards, colors } from './constants';

class DataProvider {
    brands = {};
    defaultColor = 'Temple Red';
    defaultBoard = 'Solo 18';
    baseImageURL= "http://templeplanner.com/img/";
    localStorageAvailabe = false;
    localStoregeObjSync = false;

    constructor() {
		if (typeof (window.localStorage) !== "undefined") {
            this.localStorageAvailabe = true;
        }
    }
}

export default new DataProvider();