import { boardVariables } from './constants';

export default function drawBoard(b, boardColor, context) {
    let bw = b.w / 2, bh = b.h / 2;
    context.lineCap = "round";
    context.save();

    //render board background
    context.fillStyle = "#191917";
    context.fillRect(-bw, -bh + boardVariables.boardOffsetTop, b.w, b.h - 2 * boardVariables.boardOffsetTop);

    //grid
    const xRowOff = b.xRowOff; // < 4
	const yRowOff = b.yRowOff;
	
	let xRow = xRowOff;
	let yRow = yRowOff;
	let reg = b.regstart;
    let offRow = false;

    context.fillStyle = "#6b6a66"; //"#e6e4e1";
    context.strokeStyle = "#6b6a66"; //"#e6e4e1";
    context.lineWidth = boardVariables.dotBigSize;

    const oW = boardVariables.sideWidth + boardVariables.dotOffsetLeft;
	const oH = boardVariables.dotOffsetTop;
	
    const dotLeft = -bw + oW + boardVariables.dotSize;
    const dotRight = bw - oW - boardVariables.dotSize;
    const dotTop = -bh + oH;
    const dotBottom = bh - oH;

    const dotGridX = (dotRight - dotLeft) / (b.dotw - 1);
    const dotGridY = (dotTop - dotBottom) / (b.doth - 1);

    //    context.fillRect(dotLeft, dotBottom, dotRight - dotLeft, dotTop - dotBottom);
    for (let i = 0; i < b.doth; i++) {
        let _y = dotTop - i * dotGridY;
    
        if (yRow == 2) {
            offRow = true;
            if (reg) {
                xRow = xRowOff;
            } else {
                xRow = xRowOff + 4;
            }
        }

        for (let j = 0; j < b.dotw; j++) {
            let _x = dotLeft + j * dotGridX;
            if (offRow) {
                if (xRow > 6) {
                    context.beginPath();
                    context.moveTo(_x, _y);
                    context.lineTo(_x + dotGridX, _y);
                    context.stroke();
                    j++;
                    xRow -= 6;

                } else {
                    context.beginPath();
                    context.arc(_x, _y, boardVariables.dotSize, 0, 2 * Math.PI, false);
                    context.fill();
                    xRow++;
                }
            } else {
                context.beginPath();
                context.arc(_x, _y, boardVariables.dotSize, 0, 2 * Math.PI, false);
                context.fill();
            }
        }
        yRow++;
        if (offRow) {
            offRow = false;
            reg = !reg;
            yRow = 0;
        }

    }
    context.restore();

    //sides
    context.fillStyle = boardColor;
    context.fillRect(-bw, -bh, boardVariables.sideWidth, b.h);
    context.fillRect(bw - boardVariables.sideWidth, -bh, boardVariables.sideWidth, b.h);

    //logo
    context.fillRect(- boardVariables.logoWidth / 2, -bh + boardVariables.boardOffsetTop - boardVariables.logoHeight, boardVariables.logoWidth, boardVariables.logoHeight);

    //handles
    context.strokeStyle = "#171717";
    context.lineWidth = boardVariables.handleWidth;

    let dh = (b.h - boardVariables.handleHeight) / 2;
    context.beginPath();
    context.moveTo(-bw + 0.5 * boardVariables.sideWidth, -bh + dh);
    context.lineTo(-bw + 0.5 * boardVariables.sideWidth, bh - dh);
    context.stroke();
    context.beginPath();
    context.moveTo(bw - 0.5 * boardVariables.sideWidth, -bh + dh);
    context.lineTo(bw - 0.5 * boardVariables.sideWidth, bh - dh);
    context.stroke();
}