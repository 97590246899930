import $ from "jquery"
import Vue from 'vue/dist/vue';
import { Chrome } from 'vue-color'

import data from './dataProvider';
import editor from './editor';
import { CM_TO_INCH, boards, colors } from './constants';

let searchTimeout = null;

const app = new Vue({
    el: '#app',
    components: {
        'compact-picker': Chrome,
    },
    data: {
        data: data,
        boards,
        colors,
        objects: [],
        boardsku: null,
        useSearch: false,
        searchItems: [],
        selectedBrands: {},

        showOverview: false,
        showView: false,

        customData: {
            width: "",
            height: "",
            color: {hex: '#e4b462'},
            name: '',
            sizeOptions: [
                'cm',
                'mm',
                'inch',
            ],
            size: 'inch',
        },

        selectedMenu: '',

        query: '',

        selectedBoardText: '',
        selectedBoardColor: '',
        selectedBoard: editor.state.selectedBoard,
        boardColor: editor.state.board.color,
        boardColorString: editor.state.board.string,
    },

    $mounted() {
        this.reflectValues();
    },

    methods: {
        toggleMenu(name) {
            if (this.selectedMenu == name) {
                this.selectedMenu = '';
            } else {
                this.selectedMenu = name;
            }
        },

        exportIMG() {
            editor.exportImage();
        },

        reflectValues() {
            this.selectedBoard = editor.state.selectedBoard;
            this.boardColor = editor.state.board.color;
            this.boardColorString = editor.state.board.string;
            this.$set(this, 'objects', editor.objects);
        },

        selectBoard(key) {
            editor.changeBoard(key);
            this.reflectValues();
        },

        selectColor(key, option) {
            editor.changeColor(option.color);
            this.reflectValues();
        },

        reset() {
            editor.resetObjects();
            this.reflectValues();
        },

        addPedal(parentkey, key) {
            editor.addPedalToBoard(parentkey, key);
            this.reflectValues();
        },

        search() {
            if (this.query) {
                if (searchTimeout) clearTimeout(searchTimeout);
                
                searchTimeout = setTimeout(this.setSearch.bind(this), 100);
            }
        },

        setSearch() {
            setSearch(this.query);
        },

        toggleSearch() {
            if (this.useSearch) {
                this.useSearch = false;
                this.query = '';
                document.getElementById('queryElement').blur();
            } else {
                this.useSearch = true;
                document.getElementById('queryElement').focus();
            }
        },

        setSelectedBrand(brand) {
            let set = app.selectedBrands[brand] == true;
            Vue.set(app.selectedBrands, brand, !set);
        },

        overviewString() {
            return this.objects.length == 1
                ? 'Overview (1 item)'
                : 'Overview (' + this.objects.length + ' items)'
                ;
        },

        addCustomBoard() {
            let name = this.customData.name;
            if (!name) {
                return alert('Insert a valid name!');
            }

            let width = Number(this.customData.width);
            if (isNaN(width) || width <= 0) {
                return alert('Insert a valid width (> 0)!');
            }

            let height = Number(this.customData.height);
            if (isNaN(height) || height <= 0) {
                return alert('Insert a valid height (> 0)!');
            }

            let w = width;
            let h = height;

            switch (this.customData.size) {
                case 'cm':
                    w = width * CM_TO_INCH;
                    h = height * CM_TO_INCH;
                    break;

                case 'mm':
                    w = width * 0.1 * CM_TO_INCH;
                    h = height * 0.1 * CM_TO_INCH;
                    break;
            
                default:
                    break;
            }

            editor.addCustomPedal(
                0,
                0,
                w,
                h,
                this.customData.color.hex,
                name,
            );

            // Reset
            this.customData.name = '';
            this.customData.width = 0;
            this.customData.height = 0;
            this.customData.color.hex = '#000000';
            this.showView = false;
        },
    }
});
export default app;

editor.updateBoardString();

document.getElementById('remove-object').addEventListener('click', function() {
    editor.removeSelectedObject();
});

document.getElementById('rotate-object').addEventListener('click', function() {
    editor.rotateSelectedObject();
});

document.getElementById('zoom-in').addEventListener('click', function() {
    editor.zoomStep(1);
});

document.getElementById('zoom-reset').addEventListener('click', function() {
    editor.zoomReset();
});

document.getElementById('zoom-out').addEventListener('click', function() {
    editor.zoomStep(-1);
});

$(".select .single-opt.toggleImag").click(function () {
    editor.useBoardImage = !editor.useBoardImage;
    editor.render();
});

$("form#search-item-view").submit(function (e) { e.stopImmediatePropagation(); return false; });

function setSearch(searchVal) {
    let res = [];

    let q = searchVal.toLowerCase();

    for (let key in data.brands) {
        let brand = data.brands[key];
        let matchesBrand = key.toLowerCase().indexOf(q) !== -1;
        let showBrand = matchesBrand;
        let subkeys = [];

        for (let subkey in brand) {
            if (matchesBrand || subkey.toLowerCase().indexOf(q) !== -1) {
                subkeys.push(subkey);
                showBrand = true;
            }
        }

        if (showBrand) {
            res.push({
                key: key,
                subkeys: subkeys,
            })
        }
    }
    
    app.$set(app, "searchItems", res);
}

editor.loadData("js/data.json", function (dt) {
    Vue.set(data, "brands", dt);

    if (data.localStorageAvailabe && localStorage.objects) {
        editor.loadObjectList(JSON.parse(localStorage.objects), function () {
            data.localStoregeObjSync = true;
            $(".loading-screen").hide();
        });
    } else {
        data.localStoregeObjSync = true;
        $(".loading-screen").hide();
    }
});
