export const boards = {
    "Solo 18": {
        "h": 8.5,
        "w": 18,
        "dotw": 36,
        "doth": 17,
        "yRowOff": 0,
        "xRowOff": 2,
        "regstart": false,
        "img": "boards/solo-18",
        default: true,
        text: 'SOLO 18 (46x22 cm)',
    },
    "Duo 17": {
        "w": 17,
        "h": 12.5,
        "dotw": 34,
        "doth": 25,
        "yRowOff": 0,
        "xRowOff": 3,
        "regstart": false,
        "img": "boards/duo-17",
        text: 'DUO 17 (43x32 cm)',
    },
    "Duo 24": {
        "w": 24.5,
        "h": 12.5,
        "dotw": 50,
        "doth": 25,
        "yRowOff": 0,
        "xRowOff": 3,
        "regstart": false,
        "img": "boards/duo-24",
        text: 'DUO 24 (62x32 cm)',
    },
    "Duo 34": {
        "w": 34,
        "h": 12.5,
        "dotw": 70,
        "doth": 25,
        "yRowOff": 0,
        "xRowOff": 3,
        "regstart": false,
        "img": "boards/duo-34",
        text: 'DUO 34 (86x32 cm)',
    },
    "Trio 21": {
        "w": 21,
        "h": 16.5,
        "dotw": 42,
        "doth": 33,
        "yRowOff": 1,
        "xRowOff": 3,
        "regstart": false,
        "img": "boards/trio-21",
        text: 'TRIO 21 (53x42 cm)',
    },
    "Trio 28": {
        "w": 28.2,
        "h": 16.5,
        "dotw": 58,
        "doth": 33,
        "yRowOff": 1,
        "xRowOff": 3,
        "regstart": false,
        "img": "boards/trio-28",
        text: 'TRIO 28 (72x42 cm)',
    },
    "Trio 43": {
        "w": 43.2,
        "h": 16.5,
        "dotw": 90,
        "doth": 33,
        "yRowOff": 1,
        "xRowOff": 3,
        "regstart": false,
        "img": "boards/trio-43",
        text: 'TRIO 43 (110x42 cm)',
    },
};

export const colors = {
    "Temple Red": {
        text: 'Temple Red',
        color: '#dc4101',
    },
    "Gunmetal": {
        text: 'Gunmetal',
        color: '#4f4f4f',
    },
    "Vintage White": {
        text: 'Vintage White',
        color: '#fffff7',
    },
};
export const INCH_TO_CM = 2.54;
export const CM_TO_INCH = 1 / INCH_TO_CM;

export const boardVariables = {
    dotSize: 0.3 * CM_TO_INCH,
    dotBigSize: 1.6 * CM_TO_INCH,
    sideWidth: 1.65 * CM_TO_INCH,
    handleHeight: 10.4 * CM_TO_INCH,
    handleWidth: 1 * CM_TO_INCH,
    dotOffsetTop: 1.15 * CM_TO_INCH,
    dotOffsetLeft: 0.2 * CM_TO_INCH,
    boardOffsetTop: 0.15 * CM_TO_INCH,
    logoWidth: 4.95 * CM_TO_INCH,
    logoHeight: 0.15 * CM_TO_INCH,
    dotGridXApprox: 0.465,
    dotGridYApprox: 0.478,
};

export const backplates = {
    "big": {
        width: boardVariables.dotGridXApprox * 8 - boardVariables.dotSize,
        height: boardVariables.dotGridYApprox * 8 - boardVariables.dotSize,
    },
    "medium": {
        width: boardVariables.dotGridXApprox * 6 - boardVariables.dotSize,
        height: boardVariables.dotGridYApprox * 6 - boardVariables.dotSize,
    },
    "small": {
        width: boardVariables.dotGridXApprox * 4 - boardVariables.dotSize,
        height: boardVariables.dotGridYApprox * 4 - boardVariables.dotSize,
    }
};
