import $ from "jquery"
import Vue from 'vue/dist/vue';
import tinycolor from 'tinycolor2';

import { boards, colors } from './constants';
import renderBoard from './renderBoard';
import data from './dataProvider';
import app from './index';

window.requestAnimFrame = function () {
	return (
		window.requestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		window.oRequestAnimationFrame ||
		window.msRequestAnimationFrame ||
		function (/* function */ callback) {
			window.setTimeout(callback, 1000 / 60);
		}
	);
}();

class Util {
	static linearInputFunction(current, input, min, max, delta) {
		const val = current + input * (delta || 1);
		return Math.max(min, Math.min(max, val));
	}

	static boxPointCol(p, b) {
		if (p.x < b.x) return false;
		if (p.x > b.x + b.w) return false;
		if (p.y > b.y + b.h) return false;
		if (p.y < b.y) return false;
		return true;
	}
	
	static roundRect(ctx, x, y, width, height, radius, fill) {
		if (typeof radius === 'undefined') {
		  radius = 5;
		}

		if (typeof radius === 'number') {
		  radius = {tl: radius, tr: radius, br: radius, bl: radius};
		} else {
		  var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
		  for (var side in defaultRadius) {
			radius[side] = radius[side] || defaultRadius[side];
		  }
		}

		const color = tinycolor(fill);

		ctx.beginPath();
		ctx.moveTo(x + radius.tl, y);
		ctx.lineTo(x + width - radius.tr, y);
		ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
		ctx.lineTo(x + width, y + height - radius.br);
		ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
		ctx.lineTo(x + radius.bl, y + height);
		ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
		ctx.lineTo(x, y + radius.tl);
		ctx.quadraticCurveTo(x, y, x + radius.tl, y);
		ctx.closePath();

		ctx.fillStyle = '#' + color.toHex();
		ctx.fill();

		// draw button as well
		ctx.beginPath();
		const cRadius = Math.min(width / 8, height / 8);
		ctx.arc(x + width / 2, y + height - cRadius * 2, cRadius, 0, Math.PI * 2);

		if (color.toHsl().l > 0.5) {
			ctx.fillStyle = '#222';
		} else {
			ctx.fillStyle = '#eee';
		}

		ctx.fill();
	}

	static wrapText(context, obj) {
		const text = obj.name || "";
        const words = text.split(' ');
		let line = '';
		const maxWidth = obj.w - 0.5;
		const scale = 0.04

		context.save();
		context.translate(obj.x + obj.w / 2, obj.y + obj.h * 2 / 5);
		context.rotate(obj.rotation * Math.PI * 0.5);
		context.scale(scale, scale);

		context.font = "10px Georgia";
		const lineHeight = 11;
		context.textAlign = "center";
		const color = tinycolor(obj.color);

		if (color.toHsl().l > 0.5) {
			context.fillStyle = 'black';
		} else {
			context.fillStyle = 'white';
		}

		let y = 0;

        for (let n = 0; n < words.length; n++) {
			const testLine = line + words[n] + ' ';
			const metrics = context.measureText(testLine);
			const testWidth = metrics.width * scale;

			if (testWidth > maxWidth && n > 0) {
				context.fillText(line, 0, y);
				line = words[n] + ' ';
				y += lineHeight;
			} else {
				line = testLine;
			}
		}

		context.fillText(line, 0, y);
		context.restore();
	}
}

class Editor {
	transform = {
		x: 0,
		y: 0,
		userZoom: 1,
		zoom: 1,
		objZoom: 1
	};
	
	mouse = { x: 0, y: 0 };
	mouseReal = { x: 0, y: 0 };

	elements = {
		$info: $(".obj-info"),
		$editor: $(".editor"),
	}

	state = {
		pedalMoving: false,
		movingPedalID: -1,
		mouseStartPosition: { x: 0, y: 0 },
		mouseDeltaTime: 0,
		pedalSelected: false,
		pedalSelectedID: -1,

		selectedBoard: "Solo 18",

		board: {
			color: colors.Gunmetal.color,
			name: 'Solo 18',
			string: '-tr',
		}
	};
	
	useBoardImage = false;
	currentBoardImage = new Image();
	loadingBoardImage = new Image();

	canvasID = 'planner';
	canvas = null;
	context = null;
	exportCanvas = null;
	exportContext = null;
	renderCanvas = null;
	renderContext = null;

	objects = [];

	/* INITIALISE */
	constructor() {
		if (data.localStorageAvailabe) {
			this.state.selectedBoard = localStorage.board || this.state.selectedBoard;
			this.state.board.color = localStorage.color || this.state.board.color;
			this.updateBoardString();
		}

		const canvas = document.getElementById(this.canvasID);

		this.canvas = canvas;
		this.context = canvas.getContext('2d');

		this.renderCanvas = document.createElement('canvas');
		this.renderContext = this.renderCanvas.getContext('2d');

		this.exportCanvas = document.createElement('canvas');
		this.exportContext = this.exportCanvas.getContext('2d');

		if (canvas.addEventListener) {
			this.eventListen(window, 'resize', 'onWindowResize', true);

			this.eventListen(canvas, 'mousewheel', 'onMouseScroll');
			this.eventListen(canvas, 'DOMMouseScroll', 'onMouseScroll');
			this.eventListen(canvas, 'mousedown', 'onMouseDown');
			this.eventListen(canvas, 'mouseup', 'onMouseUp');
			this.eventListen(canvas, 'mousemove', 'onMouseMove');

			this.eventListen(canvas, 'touchstart', 'onTouchStart');
			this.eventListen(canvas, 'touchend', 'onTouchEnd');
			this.eventListen(canvas, 'touchcancel', 'onTouchCancel');
			this.eventListen(canvas, 'touchmove', 'onTouchMove');
		}

		this.onWindowResize();
	}

	eventListen(obj, event, method, arg = false) {
		obj.addEventListener(event, (...x) => {
			this[method](...x)
		}, arg);
	}

	/* WINDOW EVENTS */
	onWindowResize() {
		const { canvas, transform, state, elements } = this;
		canvas.width = elements.$editor.outerWidth();
		canvas.height = elements.$editor.outerHeight();
		transform.x = canvas.width / 2;
		transform.y = canvas.height / 2;
		transform.zoom = Math.min(canvas.width, canvas.height) / Math.max(canvas.width, canvas.height);
	
		this.calculateBoardZoom();
		this.moveSelectionBox();
		this.preRenderBoard(boards[state.selectedBoard]);
		this.render();
	}
	
	/* MOUSE EVENTS */
	onMouseMove(e) {
		const { canvas, transform, mouse, mouseReal, objects, state, elements } = this;

		//calculate mouse position, in pixels on screen and pixels in canvas(with regard to zoom)
		const parentOffset = {
			left: canvas.parentElement.offsetLeft,
			top: canvas.parentElement.offsetTop
		};

		const totalZoom = transform.zoom * transform.userZoom * transform.objZoom;
		mouse.x = (e.pageX - parentOffset.left - transform.x) / totalZoom;
		mouse.y = (e.pageY - parentOffset.top - transform.y) / totalZoom;
		mouseReal.x = e.pageX - parentOffset.left;
		mouseReal.y = e.pageY - parentOffset.top;
		// $('canvas').css( 'cursor', mouseOnObject() ? 'pointer' : 'default' );

		//if hovering over object, change cursor
		const hover = this.getObjectIDByMousePosition(0.05);
		canvas.className = hover > -1 ? 'active' : "";

		if (hover > -1) {
			// const pedal = objects[hover];
			if (state.pedalSelected == false) {
				elements.$info.show();
			}

			state.pedalSelectedID = hover;
			state.pedalSelected = true;
		} else if (state.pedalSelected == true) {
			state.pedalSelected = false;
			state.pedalSelectedID = -1;
			elements.$info.hide();
		}

		//move or drag object/camera
		if (state.pedalMoving) {
			if (state.movingPedalID == "camera") {
				transform.x = mouseReal.x - state.mouseStartPosition.x;
				transform.y = mouseReal.y - state.mouseStartPosition.y;

			} else {
				const pedal = objects[state.movingPedalID];
				pedal.x = mouse.x - state.mouseStartPosition.x;
				pedal.y = mouse.y - state.mouseStartPosition.y;
			}

			this.render();
		}

		this.moveSelectionBox();
	}

	onMouseDown(e) {
		const { transform, mouse, mouseReal, objects, state } = this;
		//for checking if click
		state.mouseDeltaTime = Date.now();
		const selectedObjectID = this.getObjectIDByMousePosition();
	
		//if user clicked on object
		if (selectedObjectID >= 0) {
			//move pedal
			const pedal = objects[selectedObjectID];
			state.pedalMoving = true;
			state.movingPedalID = selectedObjectID;
			state.mouseStartPosition.x = mouse.x - pedal.x;
			state.mouseStartPosition.y = mouse.y - pedal.y;
		} else {
			state.pedalMoving = true;
			state.movingPedalID = "camera";
			state.mouseStartPosition.y = mouseReal.y - transform.y;
			state.mouseStartPosition.x = mouseReal.x - transform.x;
		}
	}

	onMouseUp(e) {
		const { state } = this;
		//clear moving, and check for click
		state.pedalMoving = false;
		state.movingPedalID = -1;
	
		if (Date.now() - state.mouseDeltaTime < 105) {
			this.onMouseClick();
		}
	
		this.storeObjects();
		this.render();
	}
	
	onMouseClick() {
		//clicking shows information
		//    var i = getObjectIDByMousePosition();
		//    if(i < 0 || i == pedalSelectedID){
		//        //toggle box
		//        pedalSelected = false;
		//        pedalSelectedID = -1;
		//        $(".obj-info").hide();
		//        return;
		//    }
		//    pedalSelectedID = i;
		//    pedalSelected = true;
		//    $(".obj-info").show();
	
		this.moveSelectionBox();
	}

	onMouseScroll() {
		//zoom
		//    var e = window.event || e; // old IE support
		//    var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
		//    zoomStep(delta);
	}

	onMouseScrollEnd() {
		//only prerender board if done with scrolling
		// preRenderBoard(data.boards[selectedBoard]);
		// render();
	}
	
	/* TOUCH EVENTS*/
	onTouchStart(e) {
		this.onTouchMove(e);
		this.onMouseDown();
	}

	onTouchEnd(e) {
		this.onMouseUp();
	}

	onTouchCancel(e) {
		return;
	}

	onTouchMove(e) {
		let px = 0;
		let py = 0;
		let pxt = 0;
		let pyt = 0;

		let tn = e.touches.length;
		
		if (tn == 0) {
			return;
		}

		for (var touch of e.touches) {
			if (touch.pageX)
				pxt += touch.pageX;

			if (touch.pageY)
				pyt += touch.pageY;
		}
		
		px = pxt / tn;
		py = pyt / tn;
		
		const en = {
			pageX: px,
			pageY: py
		}
		
		this.onMouseMove(en);
	}

	renderToContext(context, zoom) {
		const { state, objects, useBoardImage, currentBoardImage } = this;
		// renderBoard(boards[selectedBoard], context);
	
		//draw the board
		const board = boards[state.selectedBoard];
		context.drawImage(useBoardImage ? currentBoardImage : this.renderCanvas, // TODO
			-board.w / 2,
			-board.h / 2,
			board.w,
			board.h);
	
		//draw the objects
		objects.forEach((obj, i) => {
			if (i == state.movingPedalID) context.globalAlpha = 0.85;

			if (obj.rotation) {
				var dx = 0;
				var dy = 0;

				switch (obj.rotation) {
					case 1:
						dy = obj.h;
						break;
					case 2:
						dy = obj.h;
						dx = obj.w;
						break;
					case 3:
						dx = obj.w;
						break;
				}

				context.save();
				context.translate(obj.x, obj.y);
				context.rotate(obj.rotation * Math.PI * 0.5);
				
				if (obj.img) {
					context.drawImage(obj.img, -dx, -dy, obj.w, obj.h);
					context.restore();
				} else {
					Util.roundRect(context, -dx, -dy, obj.w, obj.h, 0.2, obj.color || "#333");
					context.restore();
					Util.wrapText(context, obj);
				}
			} else if (obj.img) {
				context.drawImage(obj.img, obj.x, obj.y, obj.w, obj.h);
			} else {				
				Util.roundRect(context, obj.x, obj.y, obj.w, obj.h, 0.2, obj.color || "#333");
				Util.wrapText(context, obj);
			}

			if (i == state.movingPedalID)
				context.globalAlpha = 1;
		});
	}

	render() {
		const { context, transform } = this;
		this.clearCanvas();

		//scale and center board(+ translation)
		context.translate(transform.x, transform.y);
		context.scale(transform.userZoom, transform.userZoom);
		context.scale(transform.zoom, transform.zoom);

		this.renderToContext(context, transform.objZoom);
	}

	clearCanvas() {
		this.canvas.width = this.canvas.width;
		if (this.context.resetTransform) this.context.resetTransform();
	}

	renderExport() {
		const { state, exportCanvas, exportContext, renderCanvas, renderContext } = this;

		const board = boards[state.selectedBoard];
		const zoom = 1920 / (board.w * 1.2);
		const offX = board.w * zoom * 0.1;
		const offY = board.h * zoom * 0.1;
		exportCanvas.width = board.w * zoom + 2 * offX;
		exportCanvas.height = board.h * zoom + 2 * offY;
	
		//draw a new board on the canvas
		renderCanvas.width = exportCanvas.width - 2 * offX;
		renderCanvas.height = exportCanvas.height - 2 * offY;
		renderContext.scale(zoom, zoom);
		renderContext.translate(board.w / 2, board.h / 2);
	
		//actual drawing
		renderBoard(board, state.board.color, renderContext);

		//scale and center board(+ translation)
		//fill bg
		exportContext.fillStyle = "#e6e4e1";
		exportContext.fillRect(0, 0, exportCanvas.width, exportCanvas.height);
	
		exportContext.translate(exportCanvas.width / 2, exportCanvas.height / 2);
		exportContext.scale(zoom, zoom);
	
		this.renderToContext(exportContext, 1);
		this.preRenderBoard(board);
	}
	
	preRenderBoard(board) {
		const { transform, renderCanvas, renderContext, state } = this;
		//draw a new board on the canvas
		renderCanvas.width = renderCanvas.width;
		const scl = transform.userZoom * transform.zoom;
		renderCanvas.width = board.w * scl;
		renderCanvas.height = board.h * scl;
		renderContext.scale(scl, scl);
		renderContext.translate(board.w / 2, board.h / 2);

		//actual drawing
		renderBoard(board, state.board.color, renderContext);
	}
	
	getObjectIDByMousePosition(marginValue) {
		const { mouse, objects } = this;
		const margin = marginValue || 0;
		let returnObjectID = -1;

		objects.forEach((pedal, i) => {
			const px = parseFloat(pedal.x);
			const py = parseFloat(pedal.y);

			let pw = parseFloat(pedal.w);
			let ph = parseFloat(pedal.h);

			if (pedal.rotation == 1 || pedal.rotation == 3) {
				// swap width and height
				const temp = pw;
				pw = ph;
				ph = temp;
			}

			let hover = false;

			if (margin != 0) {
				hover = Util.boxPointCol(
					mouse,
					{
						x: px - margin * pw,
						y: py - margin * ph,
						w: pw * (1 + 2 * margin),
						h: ph * (1 + 2 * margin),
					}
				);
			} else {
				hover = Util.boxPointCol(
					mouse,
					{
						x: px,
						y: py,
						w: pw,
						h: ph,
					}
				);
			}

			if (hover)
				returnObjectID = i;
		});

		return returnObjectID;
	}
	
	/* LOGIC FUNCTIONS */
	calculateBoardZoom() {
		const { transform, canvas, state } = this;
		const board = boards[state.selectedBoard];

		transform.zoom = Math.min(
			canvas.width / Math.min(1.25 * board.w, canvas.width),
			canvas.height / Math.min(1.25 * board.h, canvas.height)
		);
	}

	moveSelectionBox() {
		const { transform, objects, state, elements } = this;

		if (!state.pedalSelected)
			return;

		if (objects.length < 1) {
			pedalSelected = false;
			return;
		}

		const totalZoom = transform.zoom * transform.userZoom * transform.objZoom;
		const pedal = objects[state.pedalSelectedID];
		const xx = (pedal.x) * totalZoom + transform.x;
		const yy = (pedal.y) * totalZoom + transform.y;

		let dimx = pedal.w * totalZoom;
		// let dimy = 16;

		//    if(xx < dimx/2)xx = dimx/2;
		//    if(xx > canvas.width - dimx/2)xx = canvas.width - dimx/2;
		//    if(yy < 0)yy = 0;
		//    if(yy > canvas.height - dimy)yy = canvas.height - dimy;

		elements.$info.css({
			"left": xx - 8,
			"top": yy - 8,
			"width": dimx + 16 > 49 ? 50 : 25,
		});
	}

	calculateNewItemPositions(newBoardID) {
		const { transform, objects, state } = this;
		let corner = "";

		const bx = {
			xmin: "none",
			xmax: "none",
			ymin: "none",
			ymax: "none",
		}

		let ii = 0;
		objects.forEach((object) => {
			ii++;

			if (object.x + object.w > bx.xmax || bx.xmax == "none")
				bx.xmax = object.x + object.w;

			if (object.y + object.h > bx.ymax || bx.ymax == "none")
				bx.ymax = object.y + object.h;

			if (object.x < bx.xmin || bx.xmin == "none")
				bx.xmin = object.x;

			if (object.y < bx.ymin || bx.ymin == "none")
				bx.ymin = object.y;
		})
		

		if (ii == 0)
			return;

		corner = (bx.ymax > -bx.ymin) ? "bottom" : "top";
		corner += (bx.xmax > -bx.xmin) ? "-right" : "-left";

		let dx, dy;
		const board = boards[state.selectedBoard];
		const newBoard = boards[newBoardID];

		switch (corner) {
			case "bottom-left":
				dx = 0.5 * (board.w - newBoard.w) / transform.objZoom;
				dy = -0.5 * (board.h - newBoard.h) / transform.objZoom;
				break;

			case "bottom-right":
				dx = -0.5 * (board.w - newBoard.w) / transform.objZoom;
				dy = -0.5 * (board.h - newBoard.h) / transform.objZoom;
				break;

			case "top-left":
				dx = 0.5 * (board.w - newBoard.w) / transform.objZoom;
				dy = 0.5 * (board.h - newBoard.h) / transform.objZoom;
				break;

			case "top-right":
				dx = -0.5 * (board.w - newBoard.w) / transform.objZoom;
				dy = 0.5 * (board.h - newBoard.h) / transform.objZoom;
				break;
		}

		for (let i in objects) {
			objects[i].x += dx;
			objects[i].y += dy;
		}
	}

	zoomStep(delta) {
		const { transform } = this;
		transform.userZoom = Util.linearInputFunction(transform.userZoom, delta, 0.5, 2, 0.1);
		this.moveSelectionBox();
		clearTimeout(this.onMouseScrollEnd);
		setTimeout(this.onMouseScrollEnd, 175);
		this.render();
	}

	zoomReset() {
		const { transform, canvas, state } = this;
		transform.userZoom = 1;
		transform.x = canvas.width / 2;
		transform.y = canvas.height / 2;
		this.moveSelectionBox();
		this.preRenderBoard(boards[state.selectedBoard]);
		this.render();
	}

	changeBoard(id, noRender) {
		const { state } = this;
		this.calculateNewItemPositions(id);
		state.selectedBoard = id;
		this.calculateBoardZoom();
		this.updateBoardString();

		if (state.useBoardImage) {
			this.loadBoard();
		}
		
		if (!noRender) {
			this.preRenderBoard(boards[id]);
			this.render();
		}

		this.storeObjects();
	}

	changeColor(color, noRender) {
		const { state } = this;
		state.board.color = color;

		switch (color) {
			case "#4f4f4f": state.board.string = "-gm"; break;
			case "#dc4101": state.board.string = "-tr"; break;
			case "#fffff7": state.board.string = "-vw"; break;
		}

		this.updateBoardString();

		if (state.useBoardImage) {
			this.loadBoard();
		} else if (!noRender) {
			this.preRenderBoard(boards[state.selectedBoard]);
			this.render();
		}

		this.storeObjects();
	}

	addCustomPedal(x, y, w, h, color, name) {
		this.objects.push({
			x,
			y,
			w,
			h,
			"pedal": 'Custom pedal',
			"brand": name,
			custom: true,
			img: null,
			name,
			color,
			"backplates": [{
				"name": "1x medium mounting plate - default",
				"sku": "TQR-M"
			}],
			"rotation": 0,
		});

		this.updateTotal();
		this.storeObjects();
		this.render();
	}

	addPedalToBoard(brandName, pedalName, startingData = {}, callback) {
		const { objects } = this;

		if (!data.brands[brandName]){
			if (callback)
				callback();
			return;
		}
		
		const pedalData = data.brands[brandName][pedalName];

		if (!pedalData) {
			if (callback)
				callback();
			return;
		}

		// const id = objects.length;
		//    if(objects[id]){
		//        let idi = 1;
		//        while(objects[id+""+idi]){
		//            idi++;
		//        }
		//        id = id + "" + idi;
		//    }

		const backPlateInfo = this.calculateNeededBackplates(pedalData);
		// let bckplt = bckpltinfo.backplate;
		// let bckpltsku = bckpltinfo.sku;

		const newImage = new Image();
		newImage.src = data.baseImageURL + "pedals/" + pedalData.file;

		const positionX = typeof startingData.x == 'undefined'
			? -pedalData.width / 2
			: startingData.x;

		const positionY = typeof startingData.y == 'undefined'
			? -pedalData.height / 2
			: startingData.y;

		const newPedal = {
			x: positionX,
			y: positionY,
			w: pedalData.width,
			h: pedalData.height,
			pedal: pedalName,
			brand: brandName,
			img: null,
			custom: false,
			color: "rgba(20, 20, 20, 0.2)",
			backplates: backPlateInfo,
			rotation: startingData.rotation || 0,
		};

		newImage.onload = () => {
			newPedal.img = newImage;
			this.render();
		};

		objects.push(newPedal);

		this.updateTotal();
		this.storeObjects();
		this.render();

		if (callback)
			callback();
	}

	loadBoard() {
		const { loadingBoardImage, state } = this;

		loadingBoardImage.src = data.baseImageURL + boards[state.selectedBoard].img + state.board.color + ".svg";

		loadingBoardImage.onload = () => {
			this.currentBoardImage = loadingBoardImage;
			this.render();
		}
	}

	calculateNeededBackplates(pedal) {
		if (pedal.plates) {
			const result = [];
			const _items_res = {};
			const _items = pedal.plates.split(",");

			for (let i in _items) {
				let s = _items[i];
				if (_items_res[s]) {
					_items_res[s]++;
				} else {
					_items_res[s] = 1;
				}
			}

			for (let i in _items_res) {
				let sizeName;
				switch (i) {
					case "L": sizeName = "large"; break;
					case "M": sizeName = "medium"; break;
					case "S": sizeName = "small"; break;
				}
				result.push({
					"name": sizeName + " mounting plate ",
					"sku": _items_res[i] + "x " + "TQR-" + i
				});
			}

			return result;
		}

		//TODO : dit
		// if(pedal.width > backplates.big.width * 1.25 && pedal.height > backplates.big.height * 1.25){
		//     if(pedal.width > backplates.big.width * 3 || pedal.height > backplates.big.height * 3){
		//         return {
		//             "backplate" : "2x big mounting plate",
		//             "sku": "TQR-L"
		//         };
		//     } else {
		//         return {
		//             "backplate" : "1x big mounting plate",
		//             "sku": "TQR-L"
		//         };
		//     }
		// } else if(pedal.width > backplates.medium.width * 1.25 && pedal.height){
		//     if(pedal.width > backplates.medium.width * 3 || pedal.height > backplates.medium.height * 3){
		//         return {
		//             "backplate" : "2x medium mounting plate",
		//             "sku": "TQR-M"
		//         };
		//     } else {
		//         return {
		//             "backplate" : "1x medium mounting plate",
		//             "sku": "TQR-M"
		//         };
		//     }
		// } else {
		//     if(pedal.width > backplates.small.width * 3 || pedal.height > backplates.small.height * 3){
		//         return {
		//             "backplate" : "2x small mounting plate",
		//             "sku": "TQR-S"
		//         };
		//     } else {
		//         return {
		//             "backplate" : "1x small mounting plate",
		//             "sku": "TQR-S"
		//         };
		//     }
		// }

		return [{
			"name": "1x medium mounting plate - default",
			"sku": "TQR-M"
		}];
	}

	updateTotal() {
		app.reflectValues();
	}

	updateBoardString() {
		const { state } = this;
		let bcs = "";
		let bcc = state.board.color;

		switch (state.board.color) {
			case "#4f4f4f":
				bcs = "Gunmetal";
				break;
			case "#dc4101":
				bcs = "Temple Red";
				break;
			case "#fffff7":
				bcs = "Vintage White";
				bcc = "#000009";
				break;
		}

		if (typeof (app) == "undefined")
			return;

		app.selectedBoardText = state.selectedBoard + " " + bcs;
		app.selectedBoardColor = bcc;

		this.getSetBoardSKU();
	}

	getSetBoardSKU() {
		const { state } = this;
		if (typeof (app) == "undefined")
			return;

		let boardColorString = "";

		switch (state.board.color) {
			case "#4f4f4f": boardColorString = "-GM"; break;
			case "#dc4101": boardColorString = "-TR"; break;
			case "#fffff7": boardColorString = "-VW"; break;
		}

		app.boardsku = state.selectedBoard.toUpperCase().replace(" ", "-") + boardColorString;
	}

	removeSelectedObject() {
		const { state, objects, elements } = this;
		Vue.delete(objects, state.pedalSelectedID);
		state.pedalSelected = false;

		elements.$info.hide();
		this.render();
		this.updateTotal();
		this.storeObjects();
	}

	storeObjects() {
		if (!data.localStorageAvailabe || !data.localStoregeObjSync) return;

		localStorage.objects = this.getObjectList();
		localStorage.board = this.state.selectedBoard;
		localStorage.color = this.state.board.color;
	}

	getObjectList() {
		let ret = [];

		for (let i in this.objects) {
			let obj = this.objects[i];
			ret[i] = {};
			ret[i].x = obj.x;
			ret[i].y = obj.y;
			ret[i].pedal = obj.pedal;
			ret[i].brand = obj.brand;
			ret[i].rotation = obj.rotation;
			ret[i].custom = obj.custom;
			ret[i].color = obj.color;

			if (obj.custom) {
				ret[i].name = obj.name;
				ret[i].w = obj.w;
				ret[i].h = obj.h;
			}
		}

		return JSON.stringify(ret);
	}

	loadObjectList(obj_list, callback) {
		if (obj_list.length == 0) { callback(false); return };
		let len = obj_list.length;

		const objLoaded = () => {
			if (--len == 0) {
				callback();
				console.log(this.objects)
			}
		}

		for (let i in obj_list) {
			let obj = obj_list[i];

			if (obj.custom) {
				this.addCustomPedal(
					obj.x,
					obj.y,
					obj.w,
					obj.h,
					obj.color,
					obj.name,
				);

				objLoaded();
				continue;
			}

			if (!data.brands[obj.brand]) {
				objLoaded();
				continue;
			}

			let pedalData = data.brands[obj.brand][obj.pedal];

			if (!pedalData) {
				objLoaded();
				continue;
			}

			this.addPedalToBoard(obj.brand, obj.pedal, obj, objLoaded);
		}
	}

	rotateSelectedObject() {
		//    delete objects[pedalSelectedID];
		const { objects, state } = this;
		const pedal = objects[state.pedalSelectedID];
		let curRot = pedal.rotation;

		curRot++;
		if (curRot > 3)
			curRot = 0;

		pedal.rotation = curRot;
		this.render();
		this.storeObjects();
	}

	resetObjects() {
		//    Vue.
		this.objects = [];
		if (app)
			app.objects = this.objects;
		this.state.pedalSelected = false;
		this.updateTotal();
		this.elements.$info.hide();
		this.changeBoard("Solo 18");
		this.changeColor("#dc4101");
		this.render();
		this.storeObjects();
	}

	exportImage() {
		this.renderExport();

		let win = window.open();
		win.document.write('<img src="' + this.exportCanvas.toDataURL("image/png")  + '" style="max-width:100%;" alt="exported image"></img>');
	}

	loadData(url, callback) {
		$.ajax({
			dataType: "json",
			url: url,
			success: callback
		});
	}

}

export default new Editor();